<template>
    <div class="contenedor">

        <rutaPagina :volver="false" :ruta="ruta" />

        <div class="row glass"  >
            <tituloPagina texto="Información para filtrar" />
            <form class="row">
                <div class="col-lg-4 grupo-label">
                    <label for="fecha_inicio" class="form-label">Fecha</label>
                    <div class="input-group mb-3">
                        <input type="date" id="fecha_inicio" class="form-control fecha-inicio" placeholder="Fecha inicio" aria-label="Fecha inicio">
                        <span class="input-group-text spam-hasta">hasta</span>
                        <input type="date" id="fecha_fin" class="form-control fecha-fin" placeholder="Fecha fin" aria-label="Fecha fin">
                    </div>
                </div>

                <div class="col-lg-4 grupo-label">
                    <label for="estado" class="form-label">Estado</label>
                    <select id="estado" class="form-select form-select seleccion" aria-label=".form-select-sm example">
                        <option value="0">Todos</option>
                        <option value="1">Abiertos</option>
                        <option value="2">Cerrados</option>
                    </select>
                </div>

                <div class="col-lg-4 grupo-label box-buscar">
                    <button type="button" class="btn btn-primary input-group-text">Buscar</button>                        
                </div>
            </form>
        </div>

        <div class="row glass " >                
            <div class="contenedor-tabla">
                <div class="table-responsive">
                    <table id="tblFacturasProvvedor" class="dataTable tabla-ng">
                        <thead>
                            <tr class="omitir-bordes">
                                <th scope="col" ># factura</th>
                                <th scope="col" >Fecha factura</th>
                                <th scope="col" >Fecha registro</th>
                                <th scope="col" >Valor</th>
                                <th scope="col" >Fecha pago</th>
                                <th scope="col" >Valor pago</th>
                                <th scope="col" >Saldo pendiente</th>
                                <th scope="col" >Estado</th>
                                <th scope="col" >Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="omitir-bordes">
                                <td>123456789</td>
                                <td>2021-10-04</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>$ 0.0</td>
                                <td><span class="estado-pendiente-pago" >Pendiente</span></td>
                                <td>
                                    <a @click="verDetalle(12345)" ><i class='bx bx-search-alt-2'></i></a>
                                    <a><i class='bx bx-down-arrow-circle'></i></a>
                                </td>
                            </tr> 
                            <tr class="omitir-bordes">
                                <td>123456789</td>
                                <td>2021-10-04</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>$ 0.0</td>
                                <td><span class="estado-pagado" >Pagado</span></td>
                                <td>
                                    <a @click="verDetalle(12345)" ><i class='bx bx-search-alt-2'></i></a>
                                    <a><i class='bx bx-down-arrow-circle'></i></a>
                                </td>
                            </tr> 
                            <tr class="omitir-bordes">
                                <td>123456789</td>
                                <td>2021-10-04</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>2021-10-04</td>
                                <td>$ 6.000.000</td>
                                <td>$ 0.0</td>
                                <td><span class="estado-abonado" >Abonado</span></td>
                                <td>
                                    <a @click="verDetalle(12345)" ><i class='bx bx-search-alt-2'></i></a>
                                    <a><i class='bx bx-down-arrow-circle'></i></a>
                                </td>
                            </tr> 
                                                        
                                                
                        </tbody>
                    </table>
                </div>                    
            </div>
        </div>


    </div>
</template>

<script>
import tituloPagina from '@/components/Titulopagina'
import { NTag } from 'naive-ui'
import { h, computed, nextTick, onMounted, router } from "vue";
import { useRouter, useRoute } from 'vue-router'
import datatable from 'datatables.net-bs5'
import $ from 'jquery'
import lenguajeDT from '@/assets/js/utilidades/traduccionDataTable.json'
import rutaPagina from '@/components/RutaPagina'

export default {
    name: 'Facturas proveedor',
    setup(props) {
        const router = useRouter()
        const route = useRoute()
        
        const cargarTabla = () => {
            nextTick(() => {
                $('#tblFacturasProvvedor').DataTable({
                    responsive: {
                        breakpoints: [
                        {name: 'bigdesktop', width: Infinity},
                        {name: 'meddesktop', width: 1480},
                        {name: 'smalldesktop', width: 1280},
                        {name: 'medium', width: 1188},
                        {name: 'tabletl', width: 1024},
                        {name: 'btwtabllandp', width: 848},
                        {name: 'tabletp', width: 768},
                        {name: 'mobilel', width: 480},
                        {name: 'mobilep', width: 320}
                        ]
                    },
                   "lengthMenu": [ [ 10, 25, 50, 75, 100, -1 ], [10, 25, 50, 75, 100, "Todos"] ],
                   "pageLength": 10,
                    language : JSON.parse(JSON.stringify(lenguajeDT))
                });
            });
        }

        const verDetalle = (id) => {
            router.push({
                path : '/facturas/'+id,
                query: {
                    ...route.query,
                },
            })
        }

        const ruta = [
            { nombre : 'Documentos'},
            { nombre : 'Facturas de proveedor'},
        ]

        onMounted(cargarTabla);

        return {
            cargarTabla,
            verDetalle,
            ruta,
        }
    },
    components : {
        tituloPagina,
        rutaPagina,
    }
}
</script>

<style scoped>
.box-buscar{
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.grupo-label{
    padding-right: 0;
}
</style>